<template>
  <div>
    <v-btn
      v-if="gradosAnomalia.length === 1"
      icon
      color="white"
      @click.stop="click"
    >
      <v-icon
        :color="colorBoton"
      >
        {{ iconBoton }}
      </v-icon>
    </v-btn>
    <v-menu
      v-else
      offset-y
      close-on-content-click
    >
      <template #activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          color="white"
          v-on="on"
        >
          <v-icon
            :color="colorBoton"
          >
            {{ iconBoton }}
          </v-icon>
        </v-btn>
      </template>
      <v-list
        v-if="!readonly"
      >
        <v-list-item
          v-for="grado in gradosAnomalia"
          :key="grado.idgrado_anomalia"
        >
          <v-list-item-title
            @click="click(grado)"
          >
            {{ grado.descripcion }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    gradosAnomalia: {
      type: Array,
      default: () => [],
    },
    colorBoton: {
      type: String,
      default: '',
    },
    iconBoton: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    click (data) {
      this.$emit('click', data)
    },
  },
}
</script>
